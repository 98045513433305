@import "./mixin.scss";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    max-width: 100%;
}

body {
    background-color: $light-blue;
}

h1 {
    font-size: 145px;
    color: $white;
    font-family: "europa";
    font-weight: 400;
    letter-spacing: 0;
    @include padding;

    @include small-desktop {
        font-size: 100px;
    }
    @include tablet {
        font-size: 80px;
        padding-left: 3rem;
    }
    @include mobile {
        font-size: 42px;
        padding-left: 1.5rem;
    }
}

h2 {
    font-size: 40px;
    color: $white;
    font-family: "europa";
    font-weight: 400;
    @include tablet {
        font-size: 30px;
    }
}

h3{
    font-size: 24px;
    color: $white;
    text-transform: uppercase;
    font-family: "europa";
    font-weight: 300;
}

h4 {
    font-size: 16px;
    color: $white;
    font-family: "europa";
    font-weight: 400;
}

h5 {
    font-size: 14px;
    color: black;
    font-family: "europa";
    font-weight: 400;
}

h6{
    font-family: "europa";
    font-weight: 400;
    color: $white;
    font-size: 32px;
}

h6:hover{
    color: $light-blue;
}

h6.active{
    color: $light-blue;
}

a{
    all: unset;
    cursor: pointer;
}

// regular
@font-face {
    font-family: "europa" , sans-serif;
    font-weight: 400;
    font-style: normal;
}

// light
@font-face{
    font-family: "europa", sans-serif;
    font-weight: 300;
    font-style: normal;
}

.split-text {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;
    h1 {
        position: absolute;
        bottom: -41px;
        left: 0;
        @include small-desktop {
            bottom: -28px;
        }
        @include tablet {
            bottom: -23px;
        }
        @include mobile {
            bottom: -13px;
        }
    }
}


$max-width: 2000px;

.contain {
    max-width: min(100%, $max-width);
    width: 100%;
}

.bar-contain {
    display: flex;
    justify-content: space-between;
    max-width: min(100% + 5rem, $max-width - 1.5rem);
    width: 100%;
    

}

.hero {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.gradient{
    position: absolute;
    bottom: 0;
    width: calc(100%);
    content: " ";
    height: 50%;
    background-image: linear-gradient(180deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.50) 100%);

    @include tablet {
        width: calc(100%);
    }
    @include mobile {
        width: calc(100%);
    }
}

.hero-image{
    width: 100%;
    height: 85vh;
    object-fit: cover;
    @include mobile {
        height: auto
    }
    @include tablet {
        height: auto
    }
}

.triangle-tr{
    position: absolute;
    top: 0;
    right: 0;
    width: 20%;
    z-index: 2;
}
.triangle-tr-flipped{
    position: absolute;
    top: 0;
    right: 0;
    width: 20%;
    z-index: 2;
    transform: scaleX(-1);
}

.triangle-tr.mobile{
    @include mobile {
        right: 3rem;
    }
}
